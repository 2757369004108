import { useCallback } from 'react';

import { PAGES } from '../common/pages';
import { useConfigContext } from '../contexts/ConfigContext';
import { useGetIdToken } from './useGetIdToken';
import { useRoute } from './useRoute';

export function useLogout() {
  const {
    keycloak: { paths, urls },
  } = useConfigContext();
  const getIdToken = useGetIdToken();
  const [, setRoute] = useRoute();

  const logout = useCallback(async () => {
    const idToken = await getIdToken();

    if (idToken) {
      const searchParams = new URLSearchParams({
        id_token_hint: idToken,
        post_logout_redirect_uri: `${window.origin}${PAGES.logoutSuccess.path}`,
      });

      window.open(`${urls.external}/${paths.logout}?${searchParams}`, '_self');
    } else {
      setRoute({ pathname: PAGES.logoutSuccess.path, searchParams: null });
    }
  }, [getIdToken, paths.logout, setRoute, urls.external]);

  return logout;
}
